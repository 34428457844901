#heart {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(20, 20, 20); /* Hintergrundfarbe des Canvas */
    margin: auto 0;
    overflow: hidden;
  }
  