/* SwipeMenu.css */

.swipe-menu-container {
    position: absolute;
    display: flex;
    height: 100%;
    width: 100%;
  }
  
  .menu {
    width: 200px; /* Anpassen der Breite des Menüs nach Bedarf */
    height: 1100em;
    background-color: #33333379;
    transition: transform 0.3s ease;
    transform: translateX(-200px); /* Startposition außerhalb des Bildschirms */
    z-index: 5;
  }
  
  .menu.open {
    transform: translateX(0); /* Menü einblenden */
    height: 1100em;
  }
  
  .menu ul {
    list-style: none;
    padding: 0;
    height: 100%;
  }
  
  .menu ul li a{
    padding: 10px;
    color: #fff;
    text-decoration: none;
 

  }

  li{
    margin-top: 40px;
    z-index: 12;
  }

  a{
    margin-top: 20px;
  }
  
  .content {
    flex: 1;
    padding: 20px;
  }
  
  .overlay {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.638);
    z-index: 2;
  }
  
  .frites-menu-button {
    position: absolute;
    background: none;
    border: none;
    cursor: pointer;
    z-index: 10;
    right: 5px;
  }
  
  .burger {
    display: inline-block;
    width: 30px;
    height: 20px;
    position: relative;
  }
  
  .bar1, .bar2, .bar3 {
    width: 100%;
    height: 3px;
    background-color: #fff;
    margin: 6px 0;
    transition: 0.4s;
  }
  
  /* Rotate first bar */
  .open .bar1 {
    -webkit-transform: rotate(-45deg) translate(-8px, 5px);
    transform: rotate(-45deg) translate(-8px, 5px);
    
  }
  
  /* Fade out the second bar */
  .open .bar2 {
    opacity: 0;
  }
  
  /* Rotate last bar */
  .open .bar3 {
    -webkit-transform: rotate(45deg) translate(-8px, -5px);
    transform: rotate(45deg) translate(-8px, -5px);
   
  }
  
  a{
    color:#fff;
  }

  p{
    position: absolute;
    z-index: 12;
    font-family: Arial, Helvetica, sans-serif;
    bottom: 45px;
    text-align: center;
    color: #ebab34;
    margin: 30px;
    right: 20px;
  }