.moon-clock {
  position: absolute;
        font-family: 'Arial', sans-serif;
         text-shadow: 2px 2px 2px #000; 
        background-color: #008080;
        color:#ebab34;
        display: block;
        align-items: top;
        justify-content: center;
        height: 550vh;
        margin: 0;
        width: 100%;
        background: url("https://img.freepik.com/fotos-kostenlos/digitale-kunst-mond-tapete_23-2150918805.jpg?t=st=1708779369~exp=1708782969~hmac=fa570d0f188de6fee5c3f88fd7662d4e8f6a8ba971cec40d7e38593d746fbbd3&w=360") no-repeat center center;
      background-size: cover;
      font-weight:900;
    
  }

  .text-moon{
    position: relative;
    text-align: center;
    color: #ebab34;
    z-index: 1;
  }
  
  .date-phase, .moon-phase {
    margin-top: 20px;
    font-size: 0.6em;
  }
  
  /* Hier kannst du weiteres CSS hinzufügen, um deine Komponente nach Belieben zu stylen */
 
  form{
    width: 100%;
    display:flex;
    flex-direction: column;
  }
  input, button{
    background: #008080;
    color: #fff;
    margin: 20px  auto;
    max-width: 320px;
    
  }
  
  .date{
    display: block;
  }
  
  
  h1 {
    color: #ebab34;
    font-size: 1.7rem;
    text-align: center;
   
  }
  
 .to-the-moon {
  
    margin-top: 20px;
    font-size: 1.4rem;
    color: #008080;
    padding: 5px;
    text-align: center;
  }
  
  ul, li, p{
    margin-top: 30px;
    margin-bottom: 30px;
    height: 30px;
    text-align: left;
    
  }
  .clock{
   margin-top:0;
  }
  .moon-phase{
    text-shadow: none;
  }
  label{
    display: flex;
    flex-direction: column;
  }
    